import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, Br, PrevNext, Text } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "地藏菩萨本愿经"
    }}>{`地藏菩萨本愿经`}</h1>
    <PrevNext prev='/dz/v8' next='/dz/v10' mdxType="PrevNext" /> <Br mdxType="Br" />
    <h3 {...{
      "id": "第九卷-称佛名号品"
    }}>{`第九卷 称佛名号品`}</h3>
    <Text mdxType="Text">
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="hē" zi="诃" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="yǎn" zi="演" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="tīnɡ" zi="听" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="xìnɡ" zi="兴" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="bēi" zi="悲" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiù" zi="救" mdxType="Zu" />
  <Zu yin="bá" zi="拔" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǎn" zi="演" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="zhènɡ" zi="正" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="sù" zi="速" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="jí" zi="即" mdxType="Zu" />
  <Zu yin="niè" zi="涅" mdxType="Zu" />
  <Zu yin="pán" zi="槃" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="zǎo" zi="早" mdxType="Zu" />
  <Zu yin="bì" zi="毕" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="yōu" zi="忧" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="sēnɡ" zi="僧" mdxType="Zu" />
  <Zu yin="qí" zi="祇" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="biān" zi="边" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zàn" zi="暂" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="恭" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="即" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="chāo" zi="超" mdxType="Zu" />
  <Zu yin="yuè" zi="越" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="sù" zi="塑" mdxType="Zu" />
  <Zu yin="huà" zi="画" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="biān" zi="边" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="hénɡ" zi="恒" mdxType="Zu" />
  <Zu yin="hé" zi="河" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="bǎo" zi="宝" mdxType="Zu" />
  <Zu yin="xìnɡ" zi="性" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="tán" zi="弹" mdxType="Zu" />
  <Zu yin="zhǐ" zi="指" mdxType="Zu" />
  <Zu yin="qǐnɡ" zi="顷" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="ɡuī" zi="归" mdxType="Zu" />
  <Zu yin="yī" zi="依" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="bō" zi="波" mdxType="Zu" />
  <Zu yin="tóu" zi="头" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lì" zi="历" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ěr" zi="耳" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="fǎn" zi="返" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="zhì" zi="志" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="sēnɡ" zi="僧" mdxType="Zu" />
  <Zu yin="qí" zi="祇" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="shī" zi="师" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="hǒu" zi="吼" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="ɡuī" zi="归" mdxType="Zu" />
  <Zu yin="yī" zi="依" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="dǐnɡ" zi="顶" mdxType="Zu" />
  <Zu yin="shòu" zi="授" mdxType="Zu" />
  <Zu yin="jì" zi="记" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="jū" zi="拘" mdxType="Zu" />
  <Zu yin="liú" zi="留" mdxType="Zu" />
  <Zu yin="sūn" zi="孙" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhì" zi="志" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="zhān" zi="瞻" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="xián" zi="贤" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="fàn" zi="梵" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="shòu" zi="授" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="jì" zi="记" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="pí" zi="毗" mdxType="Zu" />
  <Zu yin="pó" zi="婆" mdxType="Zu" />
  <Zu yin="shī" zi="尸" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="shù" zi="数" mdxType="Zu" />
  <Zu yin="hénɡ" zi="恒" mdxType="Zu" />
  <Zu yin="hé" zi="河" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="bǎo" zi="宝" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bì" zi="毕" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="竟" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="bǎo" zi="宝" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="相" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="恭" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="jiǔ" zi="久" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="hàn" zi="汉" mdxType="Zu" />
  <Zu yin="ɡuǒ" zi="果" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="sēnɡ" zi="僧" mdxType="Zu" />
  <Zu yin="qí" zi="祇" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="jiā" zi="袈" mdxType="Zu" />
  <Zu yin="shā" zi="裟" mdxType="Zu" />
  <Zu yin="chuánɡ" zi="幢" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chāo" zi="超" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="tōnɡ" zi="通" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="hénɡ" zi="恒" mdxType="Zu" />
  <Zu yin="hé" zi="河" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡuǎnɡ" zi="广" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bì" zi="必" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="净" mdxType="Zu" />
  <Zu yin="yuè" zi="月" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhì" zi="智" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="净" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhì" zi="智" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="jiù" zi="就" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="声" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="mǎn" zi="满" mdxType="Zu" />
  <Zu yin="yuè" zi="月" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="yuè" zi="月" mdxType="Zu" />
  <Zu yin="miàn" zi="面" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dàn" zi="但" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="功" mdxType="Zu" />
  <Zu yin="dé" zi="德" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiā" zi="家" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="bìnɡ" zi="病" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="ɡāo" zi="高" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="声" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chú" zi="除" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="余" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="xī" zi="悉" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="xiāo" zi="消" mdxType="Zu" />
  <Zu yin="miè" zi="灭" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="suī" zi="虽" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="jí" zi="极" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dònɡ" zi="动" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="yì" zi="亿" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="liǎo" zi="了" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chénɡ" zi="承" mdxType="Zu" />
  <Zu yin="sī" zi="斯" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="jiàn" zi="渐" mdxType="Zu" />
  <Zu yin="xiāo" zi="消" mdxType="Zu" />
  <Zu yin="miè" zi="灭" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="chēnɡ" zi="称" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="miè" zi="灭" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
    </Text>
    <PrevNext prev='/dz/v8' next='/dz/v10' mdxType="PrevNext" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      